import React from 'react'

export type Context = {
    extraSeo?: { [key: string]: SeoExtra }
}

export type SeoExtra = {
    title?: string
    description?: string
    thumbnail?: string
}

export const context = React.createContext<Context>({})

export const calculateContextValue = () => {
    return {
        // @ts-ignore
        extraSeo: window.extraSeo ?? {},
    }
}
