import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Cookies from 'js-cookie'
import { up } from 'styled-breakpoints'

type GDPRComponentProps = {
  // Add your prop types here
}

const LOCAL_STORAGE_GDPR_KEY = "gdprConsentObtained"

const setObtained = () => {
  localStorage.setItem(LOCAL_STORAGE_GDPR_KEY, "true")
}

const acceptConsent = () => {
  setObtained()

  // @ts-ignore
  gtag('consent', 'update', {
    'analytics_storage': 'granted'
  });
}

const declineConsent = () => {
  setObtained()

  Object.keys(Cookies.get()).forEach(function(cookieName) {
    var neededAttributes = {
      // Here you pass the same attributes that were used when the cookie was created
      // and are required when removing the cookie
    };
    Cookies.remove(cookieName, neededAttributes);
  });
}

const GDPRComponent: React.FunctionComponent<GDPRComponentProps> = () => {
  const [shouldDisplay, setShouldDisplay] = useState(false)

  useEffect(() => {
    const shown = localStorage.getItem(LOCAL_STORAGE_GDPR_KEY)
    if (shown === null) {
      setShouldDisplay(true)
    } 
  }, [])

  if (!shouldDisplay) {
    return null
  }

  return (
    <Container>
        <Body>
            <Title>We value your privacy</Title>
            <Text>
              We would like to use cookies to help us improve the website. By clicking “Accept”, you agree Byzans can store cookies on your device.
            </Text>
        </Body>
        <Button main onClick={() => { acceptConsent(); setShouldDisplay(false) }}>Accept</Button>
        <Button onClick={() => { declineConsent(); setShouldDisplay(false) } }>Decline</Button>
    </Container>
  )
}

const Container = styled.div`
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    
    margin: 20px;
    padding: 30px;

    display: grid;
    align-items: center;
    grid-template-columns: auto auto;
    grid-gap: 10px;

    border-radius: 10px;

    background: ${p => p.theme.colors.lightGray};

    z-index: 1000;

    ${up("tablet")} {
      grid-template-columns: 1fr auto auto;
    }
`

const Body = styled.div`
    display: grid;

    grid-template-columns: 1fr;
    grid-column: 1 / span 2;
    grid-gap: 10px;

    ${up('tablet')} {
      grid-column: unset;
    }
`

const Title = styled.div`
    font-weight: bold;
`

const Text = styled.div`
    margin: 0 0 15px 0;

    ${up('tablet')} {
      margin: 0 15px 0 0;  
    }
`

const Button = styled.div<{ main?: boolean }>`
    padding: 10px 20px;
    height: fit-content;
    text-align: center;

    border-radius: 10px;

    background: ${p => p.main ? p.theme.colors.accent : p.theme.colors.serviceGray};
    color: ${p => p.main ? p.theme.colors.bg : p.theme.colors.main};
    
    cursor: pointer;
`

export default GDPRComponent