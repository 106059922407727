import React, { Suspense } from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { BrowserRouter as Router } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'

import { themes } from './theme'

import Splash from './components/common/splash'
import Routes from './routes'
import { calculateContextValue, context } from './context'

import i18n from './i18n'
import { DialogProvider } from './components/common/dialog/hook'
import { AuthProvider } from './data/hooks/auth'
import GDPRComponent from './components/common/gdpr'

function App() {
  return (
    <Suspense fallback={<Splash />}>
      <Router>
        <AuthProvider>
          <context.Provider value={calculateContextValue()} >
            <I18nextProvider i18n={i18n} >
              <ThemeProvider theme={themes.light} >
                <DialogProvider>
                  <Global />
                  <Routes />
                  <GDPRComponent />
                </DialogProvider>
              </ThemeProvider>
            </I18nextProvider>
          </context.Provider>
        </AuthProvider>
      </Router>
    </Suspense>
  )
}

const Global = createGlobalStyle`
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    max-width: 100vw;
    /* overflow-x: hidden; */
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    font-size: 16px;
  }

  * {
    box-sizing: border-box;
    font-family: Montserrat, Arial, Helvetica, sans-serif
    /* font-family: Gilroy-Medium, sans, Arial; */
  }

  ::-webkit-scrollbar {
    height: 3px;
    width: 3px;
    background: none;
  }

  ::-webkit-scrollbar-thumb {
    background: #9ca5af;
    -webkit-border-radius: 1ex;
  }
`


export default App
