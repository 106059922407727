import React from 'react'
import styled, { keyframes } from 'styled-components'

const Loader: React.FunctionComponent = () => (
    <Container>
        <Image src={require('./images/loader.svg')} />
    </Container>
)

const Container = styled.div`
    width: 68px;
    height: 68px;
`

const rotate = keyframes`
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
`

const Image = styled.img`
    width: 48px;
    height: 48px;
    
    animation: 1s infinite ${rotate};
`


export default Loader