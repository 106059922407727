import React, { useEffect, useState } from 'react'

export type User = {
    id: number
    public_id: string
    first_name: string
    last_name: string
    picture: string | null
}

export type Token = {
    refresh: string
    access: string
}

type AuthHookResult = {
    user?: User
    token?: Token
    setAuth: (user: User | null, token: Token | null) => void
}

const LOCAL_STORAGE_AUTH_KEY = "userToken"

const AuthContext = React.createContext<AuthHookResult>({
    setAuth: () => {},
})

export const AuthProvider: React.FunctionComponent = (props) => {
    let [userToken, setUserToken] = useState<{ user: User, token: Token } | null>(null)

    useEffect(() => {
        if (userToken == null) {
            const rawData = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY)
            if (rawData != null) {
                const localToken = JSON.parse(rawData)
                setUserToken(localToken)
            }
        }
    }, [userToken])

    const setAuth = (user: User | null, token: Token | null) => {
        if (user == null || token == null) {
            setUserToken(null)
            localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY)
            return
        }

        setUserToken({ user, token })
        localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, JSON.stringify({ user, token }))
    }

    return (
      <AuthContext.Provider value={{ user: userToken?.user, token: userToken?.token, setAuth }} {...props} />
    )
}


export const useAuth = (): AuthHookResult => {
    const context = React.useContext(AuthContext)
    if (context === undefined) {
      throw new Error('useModal must be used within a UserProvider')
    }
  
    return context
}
