import React, { Reducer, useCallback, useReducer } from "react"

import Dialog, { DialogLevel } from "."

export { DialogLevel }

type DialogContextType = {
    closeDialog: (level?: DialogLevel) => void, 
    setComponent: (cmp: React.ReactElement, backdrop?: React.ReactElement, level?: DialogLevel) => void
}

const DialogContext = React.createContext<DialogContextType>({
  closeDialog: () => {},
  setComponent: () => {},
})

type ProviderState = {
  [key in DialogLevel]: { 
    component: React.ReactElement | undefined, 
    backdrop: React.ReactElement | undefined
  }
}

export const DialogProvider: React.FunctionComponent = (props) => {
  const [state, setState] = useReducer<Reducer<ProviderState, Partial<ProviderState>>>(
    (state, newState) => ({...state, ...newState}),
    {
      [DialogLevel.Default]: { component: undefined, backdrop: undefined},
      [DialogLevel.Top]: { component: undefined, backdrop: undefined},
    }
  )

  const setComponent = useCallback((component, backdrop, level) => {
    if (!level) { level = DialogLevel.Default }
    setState({
      [level]: {
        component, backdrop
      }
    })
  }, [])

  const closeDialog = useCallback((level?: DialogLevel) => {
    if (!level) { level = DialogLevel.Default }

    setComponent(undefined, undefined, level)
  }, [setComponent])

  return (
    <DialogContext.Provider value={{ closeDialog, setComponent }} {...props} >
      { 
        Object.values(DialogLevel)
          .filter(k => typeof k !== "string") // Object.keys() for enums return both values and keys
          .map((level) => {
            const { component, backdrop } = state[level as unknown as DialogLevel]
            return (
              <>
                {component && <Dialog onClose={closeDialog} backdrop={backdrop} level={Number(level)}>
                    { component }
                </Dialog>}
              </>
            )
          })
      }
      {props.children}
    </DialogContext.Provider>
    )
}



export const useDialog = (level?: DialogLevel): DialogContextType => {
  if (!level) {
    level = DialogLevel.Default
  }

  const context = React.useContext(DialogContext)
  if (context === undefined) {
    throw new Error('useModal must be used within a UserProvider')
  }

  return context
}