import React from 'react'
import styled, { keyframes } from 'styled-components'
import Loader from '../loader'

const Splash: React.FunctionComponent = () => (
    <Container>
        <Wrapper>
            <Loader />
        </Wrapper>
    </Container>
)

const Container = styled.div`
    display: grid;
    align-items: center;
    justify-items: center;

    width: 100vw;
    height: 100vh;
`

const Wrapper = styled.div`
    display: grid;
    align-items: center;
    justify-items: center;

    grid-template-columns: 1fr;

    grid-row-gap: 30px;
`

const animation = keyframes`
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
`

const Image = styled.img`
    animation: ${animation} 600ms ease-out infinite;

    height: 20px;
`

const Logo = styled(Image)`
    height: 70px;
`

export default Splash