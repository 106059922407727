import React from 'react'
// @ts-ignore
import lazy from 'react-lazy-ssr'
import { Switch, Route, Redirect } from 'react-router-dom'

const Awards2021 = lazy(() => import('./components/pages/awards/2021'), { chunkName: 'awards2021' })
const Awards2022 = lazy(() => import('./components/pages/awards/2022'), { chunkName: 'awards2022' })
const Awards2023 = lazy(() => import('./components/pages/awards/2023'), { chunkName: 'awards2023' })

const Share = lazy(() => import('./components/pages/share'), { chunkName: 'share' })

const Page404 = lazy(() => import('./components/pages/page-404'), { chunkName: '404' })

const LandingPage = lazy(() => import('./components/pages/landing'), { chunkName: 'landing' })

const Policy = lazy(() => import('./components/pages/landing/policies'), { chunkName: 'policies' })
const Faq = lazy(() => import('./components/pages/landing/faq'), { chunkName: 'faq' })

const LoginPage = lazy(() => import('./components/features/auth/pages/auth/login'), { chunkName: 'auth' })
const RegisterPage = lazy(() => import('./components/features/auth/pages/auth/register'), { chunkName: 'auth' })
const ForgotPasswordPage = lazy(() => import('./components/features/auth/pages/forgot'), { chunkName: 'auth' })
const FakeSuccessPage = lazy(() => import('./components/features/auth/pages/fake-success'), { chunkName: 'auth' })
const RecoverPage = lazy(() => import('./components/pages/recover'), { chunkName: 'auth' })
const DeleteAccountPage = lazy(() => import('./components/features/auth/pages/delete-account'), { chunkName: 'auth' })

const SearchPage = lazy(() => import('./components/features/search/pages/search'), { chunkName: 'book'})
const BookPage = lazy(() => import('./components/features/library/pages/book'), { chunkName: 'book' })
const LibraryPage = lazy(() => import('./components/features/library/pages/library'), { chunkName: 'book'})

const FeedPage = lazy(() => import('./components/features/feed/pages/feed'), { chunkName: 'feed'})

const MessagesPage = lazy(() => import('./components/features/messages/pages/messages-list'), { chunkName: 'messages'})
const ChatPage = lazy(() => import('./components/features/messages/pages/chat'), { chunkName: 'messages' })
const ProfilePage = lazy(() => import('./components/features/profile/pages/profile'), { chunkName: 'messages' })

export const routes: [string, React.FunctionComponent][] = [
    ['/awards2021', Awards2021],
    ['/awards/2021', Awards2021],
    ['/awards/2022', Awards2022],
    ['/awards/2023', Awards2023],

    ['/', LandingPage],
    ['/en', LandingPage],
    ['/fr', LandingPage],
    ['/de', LandingPage],
    ['/ru', LandingPage],

    ['/share', Share],
    ['/share-club', Share],
    ['/share-profile/:publicId', ProfilePage],
    ['/recover-password', RecoverPage], // deprecated
    ['/delete-account', DeleteAccountPage],
    
    ['/policy/:kind', Policy],
    ['/en/policy/:kind', Policy],
    ['/fr/policy/:kind', Policy],
    ['/de/policy/:kind', Policy],
    ['/ru/policy/:kind', Policy],
    
    ['/faq', Faq],
    ['/en/faq', Faq],
    ['/fr/faq', Faq],
    ['/de/faq', Faq],
    ['/ru/faq', Faq],

    // For legacy links from app to policies
    ["/fr/conditions-dutilisation/", () => <Redirect to='/policy/terms?lng=fr' />],
    ["/de/nutzungsbedingungen/", () => <Redirect to='/policy/terms?lng=de' />],
    ["/en/terms-of-services/", () => <Redirect to='/policy/terms?lng=en' />],
    ["/ru/пользовательское-соглашение/", () => <Redirect to='/policy/terms?lng=ru' />],
    ["/fr/politique-dutilisation-des-donnees/", () => <Redirect to='/policy/data?lng=fr' />],
    ["/de/datenrichtlinie/", () => <Redirect to='/policy/data?lng=de' />],
    ["/en/data-policy/", () => <Redirect to='/policy/data?lng=en' />],
    ["/ru/политика-использования-данных/", () => <Redirect to='/policy/data?lng=ru' />],
    ["/fr/regles-de-la-communaute/", () => <Redirect to='/policy/community?lng=fr' />],
    ["/de/gemeinschaftsregeln/", () => <Redirect to='/policy/community?lng=de' />],
    ["/en/community-rules/", () => <Redirect to='/policy/community?lng=en' />],
    ["/ru/правила-сообщества/", () => <Redirect to='/policy/community?lng=ru' />],
    ["/fr/faq-2/", () => <Redirect to='/faq?lng=fr' />],
    ["/de/faq-3/", () => <Redirect to='/faq?lng=de' />],
    ["/en/faq-en/", () => <Redirect to='/faq?lng=en' />],
    ["/ru/faq-4/", () => <Redirect to='/faq?lng=ru' />],
    ["/fr/mentions-legales/", () => <Redirect to='/policy/mentions?lng=fr' />],

    // Auth routes
    ['/auth/login', LoginPage],
    ['/auth/register', RegisterPage],
    ['/auth/forgot-password', ForgotPasswordPage],
    ['/auth/recover-password', RecoverPage],
    ['/auth/fake-success', FakeSuccessPage],

    // Book routes
    ['/book', BookPage],
    ['/search', SearchPage],
    ['/library', LibraryPage],

    ['/feed', FeedPage],
    ['/profile/:publicId', ProfilePage],

    ['/messages', MessagesPage],
    ['/messages/:entity/:section/:entityId', ChatPage],
    ['/messages/:entity/:section/:entityId/:postId', ChatPage], // For salon comments
]

const Routes: React.FunctionComponent = () => (
    <Switch>
        {
            routes.map(route => <Route exact path={route[0]} component={route[1]} key={route[0]} />)
        }

        <Route component={Page404} />
    </Switch>
)

export default Routes