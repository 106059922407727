import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

const detector = {
  name: "mydetector",
  lookup: (options: object) => {
    const fullLanguage = navigator.language
    if (!navigator.language) {
      return undefined
    }

    if (fullLanguage.startsWith("zh")) {
      if (fullLanguage.toLowerCase().includes("hant") || fullLanguage.toLowerCase().includes("tw")) {
        return "zh-TW"
      } else {
        return "zh-CN"
      }
    }

    return fullLanguage.slice(0, 2)
  }
}

const myDetector = new LanguageDetector()
myDetector.addDetector(detector)

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(myDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // debug: true,
    fallbackLng: 'en',
    defaultNS: 'landing',
    supportedLngs: ['en', 'ru', 'de', 'fr', 'es', 'zh-TW', 'zh-CN'],

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      order: ['querystring', 'path', 'cookie', 'mydetector', 'navigator'],
    },
    backend: {
      loadPath: '/translations/{{lng}}/{{ns}}.json',
    },
  })


export default i18n